import React from 'react'
import Button from 'components/globals/button'

export default ({
    content
}) => {
    return (
        <div className="dual-hero-section">
            <ul>
                {
                    content.map((items, index) => {
                        let {
                            title,
                            copy,
                            buttonLink,
                            buttonColour,
                            image
                        } = items

                        if (buttonColour === 'Orange') {
                            buttonColour = '#F15839'
                        } else {
                            buttonColour = '#6ABFAB'
                        }

                        return (
                            <li key={ index }>
                                <div style={{ backgroundImage: `url(${image.mediaItemUrl})`}} className="dual-hero-image">
                                    <section>
                                        <h3>{ title }</h3>
                                        <div className={ 'copy' } dangerouslySetInnerHTML={{ __html: copy }}/>
                                        <Button 
                                            backgroundColor={ buttonColour } 
                                            fontColor="white"
                                            url={ buttonLink.url }
                                            label={ buttonLink.title }
                                        />
                                    </section>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}