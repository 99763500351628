import React from 'react'

import ParallaxSection from 'components/globals/parallaxSection'

export default ({
    title,
    backgroundImage,
    copy,
}) => {
    return (
        <div
            className={'big hero-six'}
        >
            <ParallaxSection
                backgroundUrl={backgroundImage ? backgroundImage.mediaItemUrl : ''}
            >
                <h1>
                    {title}
                </h1>
                <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
            </ParallaxSection>
        </div>
    )
}