import React from 'react'

export default ({
    title,
    image,
    copy,
    headingid
}) => {

    return (
        <div className={ 'container live-section' } id={ headingid }>
            <img src={ image.mediaItemUrl } />
            <h3>{ title }</h3>
            <div className={ 'copy' } dangerouslySetInnerHTML={{ __html: copy }}/>
        </div>
    )
}
