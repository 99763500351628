import React from 'react'

export default ({
    title,
    image,
    copy,
    downloadFile,
    buttonText,
}) => {

    return (
        <div className={ 'text-hero' } id="playgroup-at-home">
            <div className={ 'container' }>
                <h2>{ title }</h2>
                <div className={ 'copy' } dangerouslySetInnerHTML={{ __html: copy }}/>
                <img src={ image.mediaItemUrl } className="text-hero-image"/>
                <a target='_blank' href={downloadFile.mediaItemUrl}>{ buttonText }</a>
            </div>
        </div>
    )
}