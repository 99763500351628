import React, { useEffect, useRef, useState, useContext } from 'react'
import { isEqual, throttle } from 'lodash'
import { MainContext } from "context/context"

import Hero from 'components/flexibleContent/hero'
import TextHero from 'components/flexibleContent/textHero'
import HeadingSection from 'components/flexibleContent/headingSection'
import LiveSection from 'components/flexibleContent/liveSection'
import CardGrid from 'components/flexibleContent/cardGrid'
import DownloadsSlider from 'components/flexibleContent/downloadsSlider'
import DualHero from 'components/flexibleContent/dualHero'

const components = {
    Hero,
    TextHero,
    HeadingSection,
    LiveSection,
    CardGrid,
    DownloadsSlider,
    DualHero
}

export default ({
    content,
    slug = ''
}) => {
    const {
        componentRefs,
    } = useContext(MainContext)

    const [visibleIndices, setVisibleIndices] = useState([])

    const visibleIndicesRef = useRef(visibleIndices)

    visibleIndicesRef.current = visibleIndices

    const handleScroll = () => {
        let _visibleIndices = []
        let lastVisibleComponent = null

        componentRefs.current.forEach((componentRef, index) => {
            const offset = componentRef.getBoundingClientRect().top

            let windowHeight = null

            if (typeof window != 'undefined') {
                windowHeight = window.innerHeight
            }

            if (offset < (windowHeight / 5 * 4)) {
                lastVisibleComponent = componentRef
                _visibleIndices.push(index)
            }
        })

        // Should be done statefully, but works okay for now.
        if(lastVisibleComponent && lastVisibleComponent.dataset && lastVisibleComponent.dataset.menuItemIndex){
            const menuItemIndex = parseInt(lastVisibleComponent.dataset.menuItemIndex)

            const menuItems = document.getElementsByClassName('header-link')

            for (var i = 0; i < menuItems.length; i++) {
                const item = menuItems[i]

                if(i !== menuItemIndex){
                    item.classList.remove('active')
                }else{
                    item.classList.add('active')
                }
            }
        }

        if (!isEqual(_visibleIndices, visibleIndicesRef.current)) {
            setVisibleIndices(_visibleIndices)
        }
    }

    const debouncedHandleScroll = useRef(throttle(handleScroll, 50))

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        const { current } = debouncedHandleScroll

        if (_window) {
            window.addEventListener('scroll', current)

            setTimeout(() => {
                current()
            }, 500)
        }

        return () => window.removeEventListener('scroll', current)
    }, [_window])

    const addComponent = (ref, index) => {
        if (ref) {
            const _componentRefs = [...componentRefs.current]

            _componentRefs[index] = ref

            componentRefs.current = _componentRefs
        }
    }

    return (
        <div className={slug}>
            { content.flexibleContent.map((row, index) => {
                if (!row) {
                    return null
                }

                const Component = row.__typename.replace('WPGraphQL_Page_Flexiblecontent_FlexibleContent_', '')
                const Tag = components[Component]

                const isScrolledIntoView = visibleIndices && visibleIndices.indexOf(index) !== -1

                let className = `${Component} ${isScrolledIntoView ? 'scrolled-into-view' : ''}`

                return (
                    <section
                        className={className}
                        ref={ref => addComponent(ref, index)}
                        key={index}
                        data-menu-item-index={typeof row.activeMenuItemIndex === 'number' ? row.activeMenuItemIndex : 0}
                    >
                        <Tag
                            {...row}
                            isScrolledIntoView={isScrolledIntoView}
                        />
                    </section>
                )
            })}
        </div>
    )
}

