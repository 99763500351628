import React from "react"
import { graphql } from "gatsby"

import FlexibleContent from "components/globals/flexibleContent"

import PageWrapper from 'components/globals/pageWrapper'

export default ({ data }) => {
	const {
		databaseId,
		slug,
		title,
		excerpt,
		flexibleContent,
		seoFields
	} = data.siteData.page

	const _title = (seoFields && seoFields.metaTitle) ? seoFields.metaTitle : title
	const _description = (seoFields && seoFields.metaDescription) ? seoFields.metaDescription : excerpt
	const _image = (seoFields && seoFields.ogpImage) ? seoFields.ogpImage.mediaItemUrl : null

	return (
		<>
			<PageWrapper
				title={_title}
				description={_description}
				image={_image}
			>
				<FlexibleContent
					id={databaseId}
					content={flexibleContent}
					slug={slug}
				/>
			</PageWrapper>
		</>
	)
}

export const query = graphql`
	query($databaseId: ID!) {
		siteData {
			page(id: $databaseId, idType: DATABASE_ID) {
				databaseId
				title
				slug
				date
				content(format: RENDERED)
				seoFields {
					metaTitle
					metaDescription
					ogpImage {
						mediaItemUrl
					}
				}
				flexibleContent {
					flexibleContent {
						__typename
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Hero {
							backgroundImage {
								mediaItemUrl
							}
							title
							copy
							activeMenuItemIndex
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TextHero {
							title
							copy
							image {
								mediaItemUrl
							}
							downloadFile {
								mediaItemUrl
							}
							buttonText
							activeMenuItemIndex
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_HeadingSection {
							title
							copy
							activeMenuItemIndex
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_LiveSection {
							title
							copy
							image {
								mediaItemUrl
							}
							headingid
							activeMenuItemIndex
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_CardGrid {
							title
							seriesId
							activeMenuItemIndex
							customImages {
								image {
									mediaItemUrl
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_DownloadsSlider {
							title
							copy
							items {
								title
								image {
									mediaItemUrl
								}
								file {
									mediaItemUrl
								}
							}
							activeMenuItemIndex
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_DualHero {
							content {
								title
								copy
								image {
									mediaItemUrl
								}
								buttonLink {
									url
									title
								}
								buttonColour
							}
						}
					}
				}
			}
		}
	}
`
