import React, { useContext } from 'react';
import Link from 'components/globals/link'
import { MainContext } from 'context/context'

export default ({
    date,
    title,
    url,
    image
}) => {
    const {
        isLoadingEvents,
    } = useContext(MainContext)

    return (
        <li className={ `container mobile-card ${isLoadingEvents ? 'loading' : ''}` }>
            <Link to={url}>
                <img src={image} />
                <div className="mobile-card-content">
                    <h4 className="mobile-card-date">{ date }</h4>
                    <h4>{ title }</h4>
                </div>
            </Link>
        </li>
    );
}