import React, { useContext, useState } from 'react'
import moment from 'moment'
import Link from 'components/globals/link'
import { MainContext } from 'context/context'
import Loader from 'components/globals/loader'
import Slider from 'components/globals/slider'
import MobileCard from 'components/globals/mobileCard'

import { useIsMobile } from 'utils'

export default ({
    title,
    seriesId,
    customImages
}) => {
    const {
        isLoadingEvents,
        groupedEventData,
        options: {
            defaultEventImage
        }
    } = useContext(MainContext)

    const isMobile = useIsMobile()

    const now = moment()

    const emptyArray = [0, 0, 0, 0]

    const _cards = groupedEventData[seriesId] ? groupedEventData[seriesId].filter(event => !moment(event.start.local).isBefore(now)).map(event => {
        const {
            name,
            start,
            logo,
            url
        } = event

        return {
            title: name.html,
            date: start.local,
            url,
            image: logo ? logo.original.url : undefined
        }
    }) : emptyArray.map(_ => {
        return {
            loading: true
        }
    })

    let [mobileLoadCount, setMobileLoadCount] = useState(4)
    const mobileCards = _cards.slice(0, mobileLoadCount)

    let [mobileContainerClass, setMobileContainerClass] = useState('mobile-card-container')

    const _setMobileLoadCount = count => {
        setMobileLoadCount(count)

        setMobileContainerClass('mobile-card-container loading')

        setTimeout(() => {
            setMobileContainerClass('mobile-card-container')
        }, 1000)
    }

    const getImage = (event, index) => {
        let image = defaultEventImage.mediaItemUrl

        if(event.date){
            if(customImages && customImages[index] && customImages[index].image){
                image = customImages[index].image.mediaItemUrl
            }else if(event.image){
                image = event.image
            }
        }

        return image
    }

    const contentsJsx = _cards.map((content, index) => {
        const {
            date,
            title = '<p>Loading</p>',
            url = ''
        } = content

        const _image = getImage(content, index)

        const formattedDate = moment(date).format('ddd, Do MMMM hh:mm a')

        return (
            <li
                key={index}
                className={`card-grid-items ${isLoadingEvents ? 'loading' : ''}`}
            >
                <Link to={url}>
                    {isLoadingEvents &&
                        <Loader visible={true} />
                    }
                    <div
                        className={'card-image'}
                        style={{
                            backgroundImage: `url(${_image})`
                        }}
                    />
                    <p>
                        {formattedDate}
                    </p>
                    <div className={'copy'} dangerouslySetInnerHTML={{ __html: title }} />
                    <p className="booking">
                        Book Now
                    </p>
                </Link>
            </li>
        )
    })

    return (
        <div className={`container card-grid ${isMobile && 'mobile-title'}`}>
            <h4>
                {title}
            </h4>
            {
                isMobile ?
                    <div>
                        <ul className={mobileContainerClass}>
                            {mobileCards.map((card, index) => {
                                const {
                                    date,
                                    title = 'Loading...',
                                    url = '',
                                    image
                                } = card

                                const formattedDate = moment(date).format('dddd, Do MMMM hh:mm a')

                                return (
                                    <MobileCard
                                        key={index}
                                        title={title}
                                        date={formattedDate}
                                        url={url}
                                        image={getImage(card, index)}
                                    />
                                )
                            })
                            }
                        </ul>
                        <div className="button-group">
                            <button
                                onClick={() => { _setMobileLoadCount(mobileLoadCount += 4) }}
                                className="load-more-button"
                            >
                                load more
                        </button>
                            <button
                                onClick={() => { _setMobileLoadCount(4) }}
                                className="show-less-button"
                            >
                                show less
                        </button>
                        </div>
                    </div>
                    :
                    _cards.length > 4 ?
                        <Slider
                            className={'card-grid-list'}
                            options={{
                                prevNextButtons: !isMobile,
                                wrapAround: true,
                                pageDots: true,
                                adaptiveHeight: true,
                                cellAlign: 'left',
                                contain: true
                            }}
                        >
                            {contentsJsx}
                        </Slider>
                        :
                        <ul className={'card-grid-list no-slider'}>
                            {contentsJsx}
                        </ul>
            }
        </div>
    )
}