import React from 'react'
import Slider from 'components/globals/slider'
import { useIsMobile } from 'utils'

export default ({
    title,
    copy,
    items
}) => {
    const isMobile = useIsMobile()

    return (
        <div className="slider"
        id='/Activity-Sheets'
        >
            <div className="container">
                <h2>{ title }</h2>
                <div className={ 'copy' } dangerouslySetInnerHTML={{ __html: copy }}/>
                <div>
                    <Slider
                        className={'fg'}
                        options={{
                            prevNextButtons: !isMobile,
                            pageDots: true,
                            cellAlign: 'left',
                            wrapAround: true,
                            contain: true,
                            adaptiveHeight: true
                        }}
                    >
                        {items.map((content, index) => {
                            const {
                                title,
                                image,
                                file
                            } = content

                            return (
                                <li
                                    className={ 'carousel-cell' }
                                    key={index}
                                >
                                    <a href={ file.mediaItemUrl } target='_blank' download>
                                        <div 
                                            style={{ backgroundImage: `url(${image.mediaItemUrl})` }}
                                            className="slider-image"
                                        />
                                        <h4>
                                            { title }
                                        </h4>
                                    </a>
                                </li>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    )
}
