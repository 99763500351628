import React from 'react'

export default ({
    title,
    copy,
}) => {
    return (
        <div className={ 'container heading-section' }>
            <h2>{ title }</h2>
            <div className={ 'copy' } dangerouslySetInnerHTML={{ __html: copy }}/>
        </div>
    )
}